<template>
  <div>
    <div class="router-header flex flex-wrap items-center mb-6">
      <div
        class="content-area__heading pr-4 border-0 md:border-r border-solid border-grey-light"
      >
        <h2 class="mb-1">Assessment Subjects12</h2>
      </div>
      <div class="vx-breadcrumb ml-4 md:block hidden">
        <ul class="flex flex-wrap items-center">
          <li class="inline-flex items-end">
            <a
              @click="$router.push({ path: '/admin' })"
              class="router-link-active"
              ><span class="feather-icon select-none relative"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-home h-5 w-5 mb-1 stroke-current text-primary"
                >
                  <path
                    d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"
                  ></path>
                  <polyline
                    points="9 22 9 12 15 12 15 22"
                  ></polyline></svg></span></a
            ><span class="breadcrumb-separator mx-2"
              ><span class="feather-icon select-none relative"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right w-4 h-4"
                >
                  <polyline points="13 17 18 12 13 7"></polyline>
                  <polyline points="6 17 11 12 6 7"></polyline></svg></span
            ></span>
          </li>
          <li class="inline-flex items-center">
            <a
              v-if="$route.name === 'group-assessment-subject'"
              @click="
                $router.push({
                  name: 'view-group',
                  params: { id: $route.params.id }
                })
              "
              class="router-link-active"
              >View Group</a
            >
            <a
              v-else
              @click="$router.push({ name: 'learning center presets' })"
              class="router-link-active"
              >Learning Center Presets</a
            >
            <span class="breadcrumb-separator mx-2 flex items-start"
              ><span class="feather-icon select-none relative"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right w-4 h-4"
                >
                  <polyline points="13 17 18 12 13 7"></polyline>
                  <polyline points="6 17 11 12 6 7"></polyline></svg></span
            ></span>
          </li>
          <!---->
          <li class="inline-flex">
            <span class="cursor-default" style="text-transform: capitalize;"
              >Assessment Subjects</span
            >
          </li>
        </ul>
      </div>
    </div>
    <vx-card>
      <div class="flex justify-between items-center">
        <h2>Core Skills</h2>
        <router-link :to="{ name: 'learning-centre-preset-core-skill' }"
          >Edit</router-link
        >
      </div>
      <div class="demo-alignment align-items-start">
        <div class="fill-row-loading demo-alignment align-items-start mt-0">
          <div
            class="text-center mt-0"
            v-for="coreSkill in coreSkills"
            :key="coreSkill._id"
            style="width: 95px;"
          >
            <div class="mx-auto large mb-4" style="height: 45px; width: 45px">
              <img
                v-if="coreSkill.icon != ''"
                :src="coreSkill.icon"
                width="100%"
                height="100%"
                class="inline-block"
                style="object-fit: scale-down"
              />
              <img
                v-else
                src="../../../../../assets/images/personhood/no-img.png"
                width="100%"
                height="100%"
                class="inline-block"
                style="object-fit: scale-down"
              />
            </div>
            <p class="font-semibold">
              <span class="text-icon">{{ coreSkill.name }}</span>
              <vx-tooltip 
              v-bind:text="coreSkill.description"
              position="bottom" 
              >
                <a href="#">
                  <feather-icon icon="InfoIcon" />
                </a>
              </vx-tooltip>
            </p>
          </div>
        </div>
      </div>

      <vs-divider />
      <div class="flex justify-between items-center">
        <h2>Ways Of Learning</h2>
        <router-link
          :to="{ name: 'learning-centre-preset-core-skill-ways-of-learning' }"
          >Edit</router-link
        >
      </div>
      <div class="demo-alignment align-items-start">
        <div class="fill-row-loading demo-alignment align-items-start mt-5">
          <div
            class="text-center mt-0"
            v-for="waysOfLearning in waysOfLearnings"
            :key="waysOfLearning._id"
            style="width: 95px;"
          >
            <div class="mx-auto large mb-4" style="height: 45px; width: 45px">
              <img
                v-if="waysOfLearning.icon != ''"
                :src="waysOfLearning.icon"
                width="100%"
                height="100%"
                class="inline-block"
                style="object-fit: scale-down"
              />
              <img
                v-else
                src="../../../../../assets/images/personhood/no-img.png"
                width="100%"
                height="100%"
                class="inline-block"
                style="object-fit: scale-down"
              />
            </div>
            <p class="font-semibold demo-alignmen">
              <span class="text-icon">{{ waysOfLearning.name }}</span>
              <vx-tooltip
                v-bind:text="waysOfLearning.description"
                position="bottom"
                
              >
                <a href="#">
                  <feather-icon icon="InfoIcon" />
                </a>
              </vx-tooltip>
            </p>
          </div>
        </div>
      </div>

      <vs-divider />
      <div class="flex justify-between items-center">
        <h2>STREAM</h2>
        <router-link :to="{ name: 'learning-centre-preset-core-skill-stream' }"
          >Edit</router-link
        >
      </div>
      <div class="demo-alignment align-items-start">
        <div class="fill-row-loading demo-alignment align-items-start mt-5">
          <div
            class="text-center mt-0"
            v-for="stream in streams"
            :key="stream._id"
            style="width: 95px;"
          >
            <div class="mx-auto large mb-4" style="height: 45px; width: 45px">
              <img
                v-if="stream.icon != ''"
                :src="stream.icon"
                width="100%"
                height="100%"
                class="inline-block"
                style="object-fit: scale-down"
              />
              <img
                v-else
                src="../../../../../assets/images/personhood/no-img.png"
                width="100%"
                height="100%"
                class="inline-block"
                style="object-fit: scale-down"
              />
            </div>
            <p class="font-semibold">
              <span class="text-icon">{{ stream.name }}</span>
              <vx-tooltip 
              v-bind:text="stream.description" position="top" 
              >
                <a href="#">
                  <feather-icon icon="InfoIcon" />
                </a>
              </vx-tooltip>
            </p>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      coreSkills: [],
      waysOfLearnings: [],
      streams: []
    };
  },
  methods: {
    ...mapActions("coreSkill", ["fetchCoreSkills"]),
    ...mapActions("stream", ["fetchStreams"]),
    ...mapActions("waysOfLearning", ["fetchWaysOfLearning"])
  },
  async created() {
    this.fetchCoreSkills()
      .then(res => {
        this.coreSkills = this.$store.state.coreSkill.coreSkills;
      })
      .catch(err => {
        console.error(err);
      });

    this.fetchStreams()
      .then(res => {
        this.streams = this.$store.state.stream.streams;
        this.coreSkills = this.$store.state.coreSkill.coreSkills;
      })
      .catch(err => {
        console.error(err);
      });

    this.fetchWaysOfLearning()
      .then(res => {
        this.waysOfLearnings = this.$store.state.waysOfLearning.waysOfLearning;
      })
      .catch(err => {
        console.error(err);
      });
  }
};
</script>
<style lang="scss">
.text-icon {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.text-center {
  width: 80px;
  margin-right: 20px;

  .font-semibold {
    font-size: 14px;
    line-height: 20px;
  }
}

.large {
  width: 80px;
  height: 80px;
}

.large .inline-block {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.con-vs-tooltip {
  display: inline-block;

  .feather-icon {
    position: relative;
    top: 8px;
    width: 15px;
    height: 15px;
  }
}

</style>
